<template>
  <div class="walletView">
    <van-sticky>
      <van-nav-bar :title="$t('我的钱包')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div class="balance">{{ this.$store.getters.userinfo.balance }}</div>
    <div class="tool">
      <div class="box">
        <!-- <a class @click="toRecharge">{{ $t('充值') }}</a> -->
        <a href="javascript:;" @click="toService">{{ $t('提现') }}</a>
      </div>
    </div>
    <van-tabs
      type="card"
      color="#151d31"
      background="#0e1526"
      title-inactive-color="#ffffff"
      title-active-color="#ffffff"
    >
      <!-- <van-tab>
        <template #title>{{ $t('充值记录') }}</template>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('没有更多了')"
          @load="onLoad"
          loading-text="..."
        >
          <van-cell v-for="(item, key) in list" :key="key" class="xiax_item">
            <div class="icon tag0">{{ $t('充') }}</div>
            <div class="left">
              <span class="desc">{{ item.order_no }}</span>
              <span class="money">{{ item.price }}</span>
            </div>
            <div class="right">
              <span class="time">{{ item.create_time }}</span>
              <span class="status">{{ item.status_name }}</span>
            </div>
          </van-cell>
        </van-list>
      </van-tab> -->
      <van-tab>
        <template #title>{{ $t('提现记录') }}</template>
        <van-list
          v-model="loading2"
          :finished="finished2"
          :finished-text="$t('没有更多了')"
          @load="onLoad2"
          loading-text="..."
        >
          <van-cell v-for="(item, key) in list2" :key="key" class="xiax_item">
            <div class="icon tag1">{{ $t('提') }}</div>
            <div class="left">
              <span class="desc">{{ item.order_no }}</span>
              <span class="money">{{ item.total_money }}</span>
            </div>
            <div class="right">
              <span class="time">{{ item.addtime }}</span>
              <span class="status">{{ item.status_name }}</span>
            </div>
          </van-cell>
        </van-list>
      </van-tab>
    </van-tabs>

    <van-popup v-model="buyShow" position="bottom" :style="{ height: '39%' }" class="buy">
      <van-nav-bar :title="$t('提现')" @click-right="onClickRight">
        <template #right>
          <van-icon name="cross" size="18" />
        </template>
      </van-nav-bar>
      <div class="bk-t">
        <div class="t-smeal">
          <van-cell-group>
            <van-field
              :label="$t('提现方式')"
              v-model="typename"
              @click="onShowCard"
              :placeholder="$t('请选择提现方式')"
              readonly
            />
          </van-cell-group>
          <van-cell-group>
            <van-field :label="$t('提现金额')" v-model="withdraw_money" :placeholder="$t('请输入提现金额')" />
          </van-cell-group>
          <van-cell-group>
            <van-field
              :label="$t('资金密码')"
              type="password"
              v-model="paypwd"
              :placeholder="$t('请输入资金密码')"
            />
          </van-cell-group>
        </div>
      </div>
      <div class="buy_btn text-center">
        <van-button type="danger" class="big_other_btn2" @click="submitHandler">{{ $t('提交') }}</van-button>
      </div>
    </van-popup>
    <van-popup v-model="showCard" position="bottom" :style="{ height: '39%' }" class="showCard">
      <van-picker show-toolbar :columns="columns" @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { formatDate } from "@/utils/func";
import { getRechargeLists } from "@/api/recharge";
import { collectionsLists } from "@/api/collections";
import { add_withdraw, getWithdrawlLists } from "@/api/withdrawl";

export default {
  data() {
    return {
      title: "全部",
      type: this.$route.query.type,
      list: [],
      list2: [],
      page: 1,
      page2: 1,
      pageSize: 15,
      pageSize2: 15,
      loading: false,
      loading2: false,
      finished: false,
      finished2: false,
      buyShow: false,
      withdraw_money: "",
      paypwd: "",
      typename: "",
      showCard: false,
      columns: [],
      user_gathering_id: ""
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.GetCardLists();
  },
  watch: {},
  methods: {
        toService() {
      const url =  this.$store.getters.userinfo.app_download_url; // 替换为你要打开的地址
      // 使用 window.open 打开新网页
      window.open(url, '_blank');
    },
    submitHandler(e) {
      add_withdraw({
        withdraw_money: this.withdraw_money,
        lang: this.$lang,
        user_gathering_id: this.user_gathering_id,
        paypwd: this.paypwd
      })
        .then(response => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true
          });
          setTimeout(() => {
            this.buyShow = false;
            this.list2 = [];
            this.page2 = 1;
            this.finished2 = false;
          }, 500);
        })
        .catch(error => {});
    },
    onShowCard() {
      this.showCard = true;
    },
    GetCardLists() {
      collectionsLists({})
        .then(response => {
          response.result.forEach((val, key) => {
            this.columns.push({
              id: val.id,
              text: val.bank_name + " " + val.account
            });
          });
        })
        .catch(error => {});
    },
    onConfirm(value, index) {
      this.typename = value.text;
      this.user_gathering_id = value.id;
      this.showCard = false;
    },
    onCancel() {
      this.showCard = false;
    },
    toWithDrawl() {},
    onClickRight() {
      this.buyShow = false;
    },
    showWithDrawl() {
      this.buyShow = true;
    },
    onChange() {},
    onClickLeft() {
      this.$router.push("/main/user");
    },
    toRecharge() {
      this.$router.push("/main/user/recharge");
    },
    GetRechargeLists() {
      getRechargeLists({
        page: this.page,
        pageSize: this.pageSize
      })
        .then(response => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch(error => {});
    },
    GetWithdrawlLists() {
      getWithdrawlLists({
        page: this.page2,
        pageSize: this.pageSize2
      })
        .then(response => {
          this.list2.push.apply(this.list2, response.result);
          // 加载状态结束
          this.loading2 = false;
          this.page2++;
          if (response.result.length == 0) {
            this.finished2 = true;
          }
        })
        .catch(error => {});
    },
    onLoad2() {
      this.GetWithdrawlLists();
    },
    onLoad() {
      this.GetRechargeLists();
    }
  }
};
</script>
<style lang="stylus">
.walletView
  .balance
    background-color: #151d31
    color: #fff
    font-size: 0.8rem
    font-weight: bold
    text-align: center
    padding: 0.5rem 0.3rem
    line-height: 1
    position: relative
    z-index: 3
  .tool
    position: relative
    z-index: 2
    padding: 0 0.35rem
    margin-bottom:0.3rem
    .box
      border-radius: 0.15rem
      background-color: #fff
      box-shadow: 0 0.06rem 0.15rem #888
      overflow: hidden
      position: relative
      z-index: 2
      display: flex
      align-items: center
      padding: 0.3rem 0
    a
      flex: 1
      text-align: center
      font-size: 0.46rem
      color: #4087f1
      line-height: 0.625rem
      font-weight: 600
    a+a
      border-left: 0.0325rem solid #4087f1
  .van-tabs--card>.van-tabs__wrap
    height: 1rem
  .van-tabs__nav--card
    height: 1rem
    .van-tab
      line-height: 0.94667rem
  .van-list
    margin-top: 0.4rem
    .xiax_item
      background-color: #151d31
      margin-bottom: 0.15rem
      line-height: 0.6rem
      padding: 0.15rem 0.4rem
      span
        display: block
    .van-cell__value
      display: flex
      color: #c0c4cc
      align-items: center
      .icon
        color: #fff
        width: 0.75rem
        height: 0.75rem
        line-height: 0.75rem
        text-align: center
        border-radius: 100%
        font-size: 0.325rem
        margin-right: 0.15rem
        flex: none
        overflow: hidden
      .tag0
        background-color: #dd6161
      .tag1
        background-color: #4040ca
      .left
        font-size: 0.32rem
        padding: 6px
        .desc
          font-weight: 500
        .money
          color: $specColor
          font-weight: bold
          font-size: 0.42rem
      .right
        flex: 1
        font-size: 0.34rem
        padding: 6px
        text-align: right
        .time
          font-weight: 450
  .buy
    background: #151d31
    .van-nav-bar
      background-color: transparent
    .bk-t
      word-break: break-all
      .t-smeal
        border-radius: 0 0 0.2rem 0.2rem
        margin-bottom: 0.2rem
        line-height: 1.25rem
        font-size: 0.46rem
        border-top-left-radius: 0.2rem
        border-top-right-radius: 0.2rem
        .van-cell
          font-size: 0.32rem
          align-items: center
        .van-field__label
          color: #bbb
          line-height: 0.36rem
        .type
          display: flex
          color: #323233
          font-size: 0.37333rem
          padding: 0 0.42667rem
          .van-radio-group
            display: flex
            .van-radio
              margin-right: 0.4rem
        .money
          .van-field__control
            font-weight: bold
            font-size: 0.42rem
            color: #ff0000 !important
        .cell-v
          border-bottom: 1px dashed #ccc
          line-height: 0.46rem
          height: 0.66rem
          margin-bottom: 0.4rem
          display: flex
          p
            flex: 1
            font-weight: 500
            color: $grayTextColor
            font-size: 0.36rem
          .bk-color2
            color: #ff0000
            font-size: 0.36rem
            em
              font-size: 0.56rem
        .brand_desc
          color: #666
          font-size: 0.36rem
          p
            margin-bottom: 0.2rem
    .buy_btn
      display: flex
      padding: 0 0.5rem
      button
        width: 80%
        flex: 1
        height: 1rem;
        font-size: 0.42rem;
  .showCard
    .van-picker__confirm
      color: #4087f1
    .van-picker
      background-color: #151d31
      .van-picker__toolbar
        color: #ffffff
      .van-picker-column__item
        color: #ffffff
      .van-picker__mask
        background-image: linear-gradient(180deg, rgba(21, 29, 49, 0.9), rgba(21, 29, 49, 0.4)), linear-gradient(0deg, rgba(21, 29, 49, 0.9), rgba(21, 29, 49, 0.4))
</style>
