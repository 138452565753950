import request from '@/utils/request';
import Vue from 'vue';

export function addUserBank(data) {
  return request({
    url: '/Usergathering/edit_user_gathering?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function bankLists(data) {
  return request({
    url: '/bank/lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function collectionsLists(data) {
  return request({
    url: '/Usergathering/user_gathering_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function collectionsDetail(data) {
  return request({
    url: '/Usergathering/get_gathering_bytype?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
